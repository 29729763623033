// Libraries
import { useSelector } from 'react-redux';

// Ducks
import { StoreState } from '@/ducks';
import { selectUserId } from '@/ducks/user/selectors';

export const useUserId = () => {
    return useSelector( ( state: StoreState ) => ( selectUserId( state ) ) );
};
