'use client';

import React from 'react';
import LandingModule from '@/modules/LandingModule/LandingModule';

const Page = () => {
    return (
        <LandingModule />
    );
};

export default Page;
