import { createSelector } from 'reselect';

import { StoreState } from '../index';
import { FeatureComparison } from 'app/company/profile/billing/types';

const getOpDurationLimit = ( { config }: StoreState ): number => {
    return config && config.opDurationLimit
        ? JSON.parse( config.opDurationLimit )
        : 0;
};

const getAdjustmentLimits = ( { config }: StoreState ): { [ key: string ]: number } => {
    return config
        ? {
            businessUserBidAdjustmentLimitPieceWork: config.businessUserBidAdjustmentLimitPieceWork || 250
            , businessUserBidAdjustmentLimitTaskWork: config.businessUserBidAdjustmentLimitTaskWork || 200
            , internalUserBidAdjustmentLimitPieceWork: config.internalUserBidAdjustmentLimitPieceWork || 400
            , internalUserBidAdjustmentLimitTaskWork: config.internalUserBidAdjustmentLimitTaskWork || 400
        }
        : {
            businessUserBidAdjustmentLimitPieceWork: 250
            , businessUserBidAdjustmentLimitTaskWork: 200
            , internalUserBidAdjustmentLimitPieceWork: 400
            , internalUserBidAdjustmentLimitTaskWork: 400
        };
};

const getLeadTimeOptions = ( { config }: StoreState ): number[] => {
    return config?.leadTimeOptions
    ?? [
        0
        , 12
        , 24
        , 36
        , 48
        , 60
        , 72
    ];
};

const getBulkAcceptanceConcurrency = ( { config }: StoreState ): number => config?.bulkAcceptanceConcurrency ?? 2;

const getMaxOperatorsNeeded = ( { config }: StoreState ): number => {
    return config && config.maxOperatorsNeeded
        ? JSON.parse( config.maxOperatorsNeeded )
        : 40;
};

const getFeatureComparisons = ( { config }: StoreState ): FeatureComparison[] => {
    return config.featureComparisons ?? [];
};

const getReadOnlyBillingPageLegacyInvoicingBusinessIds = ( { config }: StoreState ): number[] => {
    return config.readOnlyBillingPageLegacyInvoicingBusinessIds ?? [];
};

const getIsPopoverTrackerOn = ( { config }: StoreState ): boolean => {
    return config.isPopoverTrackerOn ?? true;
};

export const selectIsPopoverTrackerOn = createSelector(
    getIsPopoverTrackerOn
    , isPopoverTrackerOn => isPopoverTrackerOn
);

export const selectFeatureComparisons = createSelector(
    getFeatureComparisons
    , featureComparisons => featureComparisons
);

export const selectOpDurationLimit = createSelector(
    getOpDurationLimit
    , opDurationLimit => opDurationLimit
);

export const selectAdjustmentLimits = createSelector(
    getAdjustmentLimits
    , adjustmentLimits => adjustmentLimits
);

export const selectMaxOperatorsNeeded = createSelector(
    getMaxOperatorsNeeded
    , maxOperatorsNeeded => maxOperatorsNeeded
);

export const selectLeadTimeOptions = createSelector(
    getLeadTimeOptions
    , leadTimeOptions => leadTimeOptions
);

const getWfmUserList = ( { config }: StoreState ): number[] => {
    return config?.wfmUsers;
};
export const selectWfmUserList = createSelector(
    getWfmUserList
    , wfmUserList => wfmUserList
);

export const selectReadOnlyBillingPageLegacyInvoicingBusinessIds = createSelector(
    getReadOnlyBillingPageLegacyInvoicingBusinessIds
    , readOnlyBillingPageLegacyInvoicingBusinessIds => readOnlyBillingPageLegacyInvoicingBusinessIds
);

export const selectBulkAcceptanceConcurrency = createSelector(
    getBulkAcceptanceConcurrency
    , bulkAcceptanceConcurrency => bulkAcceptanceConcurrency
);
