'use client';

import {
    useCallback
    , useEffect
    , useState
} from 'react';
import {
    useDispatch
    , useSelector
} from 'react-redux';

// Ducks
import { StoreState } from '@/ducks/index';
import {
    checkForSession
    , setSelectedBusiness
} from '@/ducks/user';
import {
    selectBusinessId
    , selectIsInternalUser
    , selectIsMultiBusinessUser
    , selectIsSessionCheckPending
    , selectUserEmail
    , selectUserLoggedIn
    , selectUserLogoutPending
    , selectBusinessDistrict
} from '@/ducks/user/selectors';

// MUI
import LoginLayout from '@/layouts/LoginLayout/LoginLayout';

// Libraries
import { useRouter } from 'next/navigation';

// Utils
import { selectWfmUserList } from '@/ducks/config/selectors';
import { getCookie } from '@/utils/cookies';

// Hooks
import { useDevPreferences } from '@/hooks/useDevPreferences';

const LandingContainer = () => {
    const email = useSelector( ( state: StoreState ) => selectUserEmail( state ) );
    const loggedIn = useSelector( ( state: StoreState ) => selectUserLoggedIn( state ) );
    const logoutPending = useSelector( ( state: StoreState ) => selectUserLogoutPending( state ) );
    const sessionCheckPending = useSelector( ( state: StoreState ) => selectIsSessionCheckPending( state ) );
    const isInternalUser = useSelector( ( state: StoreState ) => selectIsInternalUser( state ) );
    const isMultiBusinessUser = useSelector( ( state: StoreState ) => selectIsMultiBusinessUser( state ) );
    const selectedBusinessId = useSelector( ( state: StoreState ) => selectBusinessId( state ) );
    const businessDistrict = useSelector( ( state: StoreState ) => selectBusinessDistrict( state ) );
    const wfmUserList = useSelector( ( state: StoreState ) => selectWfmUserList( state ) );
    const [ isMounted, setIsMounted ] = useState<boolean>( false );
    const router = useRouter();
    const [ isDelayPassed, setIsDelayPassed ] = useState<boolean>( false );
    const dispatch = useDispatch();
    const rememberSelectedBusiness = useDevPreferences( 'rememberSelectedBusiness' );

    const redirectLoggedInUser = useCallback( async (): Promise<void> => {
        const fromRoute = sessionStorage?.getItem( 'fromRoute' );
        let path;
        if ( isInternalUser ) {
            if ( fromRoute && (
                fromRoute.includes( '/company' )
            ) ) {
                path = '/business-select';
            } else if ( fromRoute ) {
                path = fromRoute;
            } else {
                path = '/business-select';
            }
        } else if ( isMultiBusinessUser ) {
            path = '/business-select';
        } else if ( fromRoute ) {
            path = fromRoute;
        } else {
            path = '/company/dashboard';
        }
        const wfmBusiness = wfmUserList?.includes( selectedBusinessId );
        if ( wfmBusiness ) {
            router.push( '/company/scheduler/summary' );
            return;
        }
        router.push( path );
        return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isInternalUser, isMultiBusinessUser, router, selectedBusinessId ] );

    useEffect( () => {
        if ( logoutPending ) {
            router.push( '/login' );
        } else if ( loggedIn ) {
            redirectLoggedInUser();
        } else if ( !sessionCheckPending && isMounted && isDelayPassed ) {
            router.push( '/login' );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ loggedIn, sessionCheckPending, logoutPending, isMounted, isDelayPassed ] );

    useEffect( () => {
        const fromRoute = sessionStorage?.getItem( 'fromRoute' );

        if ( loggedIn && rememberSelectedBusiness ) {
            const selectedBusiness = JSON.parse( localStorage?.getItem( `devSelectedBusiness_${ process.env.NEXT_PUBLIC_ENVIRONMENT }` ) || 'false' );
            if ( selectedBusiness ) {
                dispatch( setSelectedBusiness( selectedBusiness ) );
                router.push( fromRoute ?? '/company/dashboard' );
            }
        }

    }, [ loggedIn, rememberSelectedBusiness ] );

    const checkForUserSession = useCallback( () => {
        const skipSessionCheckValue = getCookie( 'skipSessionCheck' );
        if ( skipSessionCheckValue ) return;
        dispatch( checkForSession() );
    }, [ dispatch ] );

    useEffect( () => {
        setIsMounted( true );
        setIsDelayPassed( true );
        checkForUserSession();
        return () => {
            setIsMounted( false );
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    return (
        <LoginLayout />
    );
};

export default LandingContainer;
