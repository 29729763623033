// Libraries
import { useQuery } from '@tanstack/react-query';

// Hooks
import { useUserId } from './useUserId';

export const useDevPreferences = ( preferenceId: string ): boolean => {
    const isNotProd = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production';
    const userId = useUserId();
    const { data } = useQuery( {
        queryKey: [ 'admin', 'dev', 'preferences', userId, preferenceId ]
        , queryFn: () => {
            return fetch( `/api/admin/dev/preferences/${ userId }?preferenceId=${ preferenceId }` )
                .then( response => response.json() );
        }
        , enabled: !!userId && isNotProd
    } );
    return JSON.parse( data ?? 'false' );
};
